<template>
  <div class="check-inpage">
    <div class="check-l">
      <div class="check-l__top">
        <el-form label-position="top">
          <el-form-item label="日期:">
            <el-date-picker
                v-model="dateVal"
                type="date"
                placeholder="选择日期"
                @change="dataValChange"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="房间筛选:">
            <el-select v-model="roomVal" placeholder="房间类型" @change="roomValChange">
              <el-option v-for="item in roomType" :label="item.name" :value="item.pid" :key="item.pid"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="check-l__bottom">
        <div class="check-l__btn">
          <el-button class="btn" type="primary" @click="setState('1')">已入住</el-button>
        </div>
        <div class="check-l__btn">
          <el-button class="btn" type="info" @click="setState('0')">空净房</el-button>
        </div>
      </div>
    </div>
    <div class="check-r">
      <section v-for="item in roomList" :key="item.pid">
        <div class="check-r__title">
          房间类型({{ item.name }}）
        </div>
        <div class="check-r__list">
          <div class="check-r__item" :class="{'item__in':room.state=='1'}" v-for="room in item.roomStatusVOList"
               @click="handleInPopup(room,item)"
               :key="room.pid">
            <div class="item__state " :class="room.state=='1'?'state__in':'state__wait'">
              {{ room.state == '1' ? '已入住' : '待入住' }}
            </div>
            <div class="item_no">房间号{{ room.name }}</div>
            <div class="item_name" v-if="room.state=='1'">{{ room.userName }}</div>
            <div class="item_date" v-if="room.state=='1'">离店日期:{{ room.leaveDate }}</div>
          </div>
        </div>
      </section>
    </div>
    <el-dialog title="办理入住" custom-class="custom-dialog" :visible.sync="checkPopup" :modal="false" :append-to-body="true"  :lock-scroll="true">
      <div class="dialog-hd" slot="title">
        <span>办理入住</span>
      </div>
      <div class="dialog-main">
        <div class="dialog-title">房间信息</div>
        <div class="room-info">
          <div class="room-info__block">
            <!--<div class="room-info__name">房间1</div>-->
            <div class="room-info__name">房间</div>
            <div class="room-info__item" style="width: 100%;">
              <div class="room-info__num">{{ inRoomObj.name }}（{{ inRoomObj.roomType }}）</div>
              <div class="room-info__range">{{ orderObj.checkInData }}入住----{{ orderObj.leaveData }}退房</div>
              <div class="room-info__days">{{ orderObj.dayNum === 1 ? orderObj.dayNum : orderObj.dayNum - 1 }}晚</div>
            </div>
          </div>
          <!--<template v-if="orderObj.roomNum>1">-->
          <!--  <div class="room-info__block" v-for="i in orderObj.roomNum-1" :key="i">-->
          <!--    <div class="room-info__name">房间{{ i + 2 }}</div>-->
          <!--    <div class="room-info__item">-->
          <!--      <div class="room-info__num room-info__sel">点击选择房间</div>-->
          <!--      <div class="room-info__range">{{ orderObj.checkInData }}入住&#45;&#45;&#45;&#45;{{ orderObj.leaveData }}退房</div>-->
          <!--      <div class="room-info__days">{{ orderObj.dayNum }}晚</div>-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</template>-->
        </div>
        <div class="dialog-title">入住人信息</div>
        <div class="room-user">
          <div class="room-user__block" v-for="(item,index) in roomNum" :key="index">
            <!--<div class="room-user__block__num">房间{{ index + 1 }}：</div>-->
            <div class="room-user__block__num">房间：</div>
            <el-row :gutter="20" style="width: 100%;"  v-for="(user,userI) in item.inUserList" :key="userI">
              <el-col :span="9">
                <div class="room-user__col">
                  <span class="lab">入住人姓名：</span>
                  <el-input class="input" v-model="user.checkInName" placeholder="请输入内容"></el-input>
                </div>
              </el-col>
              <el-col :span="9">
                <div class="room-user__col">
                  <span class="lab">证件号：</span>
                  <el-input class="input" v-model="user.checkInCard" placeholder="请输入内容"></el-input>
                </div>
              </el-col>
              <el-col :span="4">
                <div v-if="userI==0" class="room-user__btn" style="cursor:pointer;" @click="addUser(index)">
                  添加入住人
                </div>
                <div v-else class="room-user__btn" style="cursor:pointer;" @click="delUser(index,userI)">
                  删除
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="dialog-title">订单信息</div>
        <div class="order-info">
          <el-row :gutter="20">
            <el-col :span="10">
              <div class="order-info__col">
                订单金额：{{ orderObj.cope }}元
              </div>
            </el-col>
            <el-col :span="10">
              <div class="order-info__col">
                实付金额：{{ orderObj.actuallyPaid }}元
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <div class="order-info__col">
                订单编号：{{ orderObj.code }}
              </div>
            </el-col>
            <el-col :span="10">
              <div class="order-info__col">
                订单来源：{{ orderObj.source == '0' ? '小程序' : '线下' }}
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <div class="order-info__col">
                订单状态: 待使用
              </div>
            </el-col>
            <el-col :span="10">
              <div class="order-info__col">
                入离日期：{{ orderObj.checkInData }}-{{ orderObj.leaveData }}
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <div class="order-info__col">
                入住天数：{{ orderObj.dayNum }}天
              </div>
            </el-col>
            <!--<el-col :span="10">-->
            <!--  <div class="order-info__col">-->
            <!--    联系电话：{{ orderObj.checkInPhone }}-->
            <!--  </div>-->
            <!--</el-col>-->
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="order-info__col">
                联系电话: {{ orderObj.checkInPhone }}
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="order-info__col">
                订单备注：{{ orderObj.rmk }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="dialog-bt" slot="footer">
        <el-button @click="checkPopup=false">返回</el-button>
        <el-button type="primary" @click="submitInRoom">确认入住</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {fetchRoomType, fetchRoomList, fetcheOrderDetail, submitCheckin} from '@/request/campManager'

export default {
  data() {
    return {
      checkPopup: false,
      dateVal: '', // 日期
      roomType: [], // 房间类型数据
      roomVal: '', // 房间类型
      roomList: [], // 房间列表
      state: '',
      input: '',
      // orderPID: '5072139d-5f0c-47ad-93b2-47b2e9af303e', // 订单ID
      orderPID: '',
      orderObj: {},
      inRoomObj: {},
      roomTypeId: '', // 房型ID
      userObj: {
        checkInName: '',
        checkInCard: ''
      }, // 入住人对象
      canInNum: 2, // 可入住人数
      inUserList: [ // 房间入住对象
        {
          checkInName: '',
          checkInCard: ''
        }
      ],
      roomNum: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$route.query)
      this.orderPID = this.$route.query.orderPID
      console.log('19:02版本')
      // this.roomTypeId = this.$route.query.roomTypeId
      this.getRoomType()
      // this.getRoomList()
      this.fetchOrder()
    })
  },
  methods: {
    //办理入住
    async submitInRoom() {
      this.checkPopup = false
      console.log(this.roomNum[0].inUserList)
      let flag = this.roomNum[0].inUserList.some(item => item.checkInName !== '' && item.checkInCard !== '')
      console.log(flag)
      if (flag) {
        // let list = []
        let oneUser = this.roomNum[0].inUserList
        let oneName = []
        let oneCard = []
        oneUser.forEach((item, index) => {
          // if (index == oneUser.length - 1) {
          //   oneName += `${item.checkInName}`
          //   oneCard += `${item.checkInCard}`
          // } else {
          //   oneName += item.checkInName
          //   oneCard += item.checkInCard
          // }
          if (index == oneUser.length -1) {
            oneName.push(item.checkInName)
            oneCard.push(item.checkInCard)
          }
        })
        // debugger
        // 处理入住人信息
        // 第一个房间
        let params = {
          calendarId: this.roomTypeId,
          orderId: this.orderPID,
          roomId: this.inRoomObj.pid,
          roomCode: this.inRoomObj.name,
          checkInName: oneName.toString(),
          checkInCard: oneCard.toString(),
          checkInPhone: this.orderObj.checkInPhone,
          userId: this.orderObj.userId
        }
        // list.push(params)
        const res = await submitCheckin(params)
        if (res.ResultCode === 0) {
          this.$message.success('办理成功')
          await this.getRoomList()
        }
      } else {
        this.$message({
          type: 'error',
          message: '请输入完整的入住人姓名和证件号'
        })
      }
    },
    // 删除入住人
    delUser(parentIndex, childIndex) {
      this.roomNum[parentIndex].inUserList.splice(childIndex, 1)
    },
    // 添加入住人
    addUser(parentIndex) {
      let num = this.roomNum[parentIndex].inUserList.length
      if (num < this.canInNum) {
        let obj = {
          checkInName: '',
          checkInCard: ''
        }
        this.roomNum[parentIndex].inUserList.push(obj)
      }
    },
    // 显示办理入住/离店
    handleInPopup(room, item) {
      // console.log(room.state)
      if (room.state !== '1') {
        this.checkPopup = true
        this.inRoomObj = room
        this.roomTypeId = item.pid
        this.roomNum = []
        let list = [{
         checkInName: '',
         checkInCard: ''
        }]
        // this.orderObj.roomNum
        for (let i = 0; i < 1; i++) {
          this.roomNum.push({
            inUserList: list
          })
          // debugger
        }
        // room.forEach(i => {
        //   this.roomNum.push(this.inUserList)
        // })
        let canInStr = item.liveNum
        switch (canInStr) {
          case '2人':
            this.canInNum = 2
            break
          case '2大1小':
            this.canInNum = 2
            break;
          default:
            this.canInNum = parseInt(canInStr)
            break
        }
      }
    },
    // 获取订单详情
    async fetchOrder() {
      const res = await fetcheOrderDetail(this.orderPID)
      if (res.ResultCode === 0) {
        this.dateVal = res.Data.checkInData
        this.orderObj = res.Data
        console.log(this.orderObj, '订单详情')
        // productId // 日历房
        if (this.orderObj.calendarIds === null) {
          console.log('日历房')
          this.roomVal = this.orderObj.productId
        }
        // 关联房型id
        if (this.orderObj.calendarIds !== null && this.orderObj.calendarIds !== undefined && this.orderObj.calendarIds !== '') {
          console.log(213)
          let calendarIds = this.orderObj.calendarIds.split(',')
          this.roomVal = calendarIds[0]
        }
        this.getRoomList()
      }
    },
    // 获取房间列表
    async getRoomList() {
      let params = {
        roomDate: this.dateVal,
        roomTypeId: this.roomVal,
        state: this.state
      }
      console.log(params, '请求列表');
      const res = await fetchRoomList(params)
      if (res.ResultCode === 0) {
        console.log('获取房间列表', res)
        this.roomList = res.Data
      }
    },
    // 获取房间类型
    async getRoomType() {
      let params = {}
      const res = await fetchRoomType(params)
      if (res.ResultCode === 0) {
        this.roomType = res.Data
      }
    },
    // 关闭
    handleCloseDia() {
      this.checkPopup = false
    },
    dataValChange(e) {
      console.log(e)
      this.getRoomList()
    },
    roomValChange(e) {
      console.log(e)
      this.getRoomList()
    },
    setState(e) {
      this.state = e
      this.getRoomList()
    }
  }
}
</script>

<style lang="scss" scoped>
.check-inpage {
  width: 1133px;
  height: 650px;
  background: #FFFFFF;
  //padding: 50px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;

  .check-l {
    width: 312px;
    height: 650px;
    padding: 50px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__top {
    }

    &__bottom {

    }

    &__btn {
      .btn {
        width: 80px;
        margin-bottom: 32px;
        height: 38px;
      }
    }
  }

  .check-r {
    padding: 50px 0;
    overflow-y: scroll;
    flex: 1.2;
    &__title {
      width: 786px;
      border-bottom: 1px solid #f6f6f6;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 38px;
      height: 39px;
      position: relative;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        content: " ";
        width: 56px;
        height: 3px;
        background: #348DF0;
      }
    }

    &__list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &__item {
      margin: 16px 15px 12px 0;
      width: 184px;
      height: 100px;
      background: #F6F6F6;
      border-radius: 4px;
      overflow: hidden;
      padding: 7px 7px 0 14px;
      box-sizing: border-box;
      border: 2px solid #f6f6f6;
      position: relative;

      .item_no {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #8A8A8A;
        line-height: 24px;
      }

      .item_name {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #515B78;
        line-height: 24px;
        margin-bottom: 6px;
        margin-top: 6px;
      }

      .item_date {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #515B78;
        line-height: 24px;
      }

      .item__state {
        position: absolute;
        right: 7px;
        top: 10px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;

      }

      .state__in {
        color: #FF7E00;
      }

      .state__wait {
        color: #348DF0;
      }
    }

    .item__in {
      border-color: #348DF0;
      background: rgba(52, 141, 240, 0.15);

      .item_name, .item_date {
        color: #333333;
      }
    }
  }
}

.dialog-hd {
  height: 50px;
  background: #20222A;
  line-height: 50px;
  color: #fff;
  padding: 0 37px;
}

.dialog-main {
  .dialog-title {
    width: 786px;
    border-bottom: 1px solid #f6f6f6;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 38px;
    height: 39px;
    position: relative;
    margin-left: 67px;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: " ";
      width: 56px;
      height: 3px;
      background: #348DF0;
    }
  }

  .room-info, .room-user, .order-info {
    margin-left: 67px;
  }

  .room-info {
    &__block {

    }

    &__name {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #348DF0;
      line-height: 24px;
      margin: 10px 0;
    }

    &__item {
      width: 729px;
      height: 100px;
      background: rgba(52, 141, 240, 0.15);
      border: 2px solid #348DF0;
      border-radius: 4px;
      position: relative;
      padding: 16px 14px;
      box-sizing: border-box;
    }

    &__num {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 24px;
      margin-bottom: 12px;
    }

    &__sel {
      color: #FF0003;
    }

    &__range {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }

    &__days {
      position: absolute;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
      left: 60%;
      top: 38px;
    }
  }

  .room-user {
    &__block {
      padding-left: 82px;
      position: relative;
    }

    &__block__num {
      position: absolute;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #348DF0;
      line-height: 24px;
      left: 0;
      top: 10px;
    }

    &__col {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span.lab {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      .input {
        flex: 1
      }
    }

    &__btn {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FF0003;
      height: 38px;
      line-height: 38px;
      text-align: right;
    }
  }

  .order-info {
    margin: 32px 0 32px 149px;

    &__col {
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
</style>
<style lang="scss">

.custom-dialog {
  .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
      top: 14px;
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
      font-size: 24px;
    }
  }
}

</style>
